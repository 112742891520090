 import React from 'react'
 import 'bootstrap/dist/css/bootstrap.min.css';
import Formm from './Form/Form';
 
 function App() {
   return (
    <Formm/>
   )
 }
 
 export default App